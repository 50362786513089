.action {
    padding: 8px;
    border: 1px solid #fff;
    border-radius: 100rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: transparent;
    height: auto;
    position: relative;
    &:hover {
        [role="tooltip"] {
            transform: scale(1);
        }
    }
    [role="tooltip"] {
        top: calc(100% + 6px);
        right: 0;
        transform-origin: right top;
        &::before {
            top: -6px;
            height: 6px;
        }
    }
    [role="icon-default"] {
        color: #fff;
        width: 24px;
        height: 24px;
    }
    .loading {
        width: 24px;
        height: 24px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        .player {
            width: 56px;
            height: 56px;
            * {
                stroke: #fff !important;
            }
        }
    }
    &:not(.disable):hover {
        background-color: #292929;
        border-color: #fff !important;
    }
    &.disable {
        border-color: rgba(65, 65, 65, 1) !important;
        [role="icon-default"] {
            color: rgba(65, 65, 65, 1) !important;
            * {
                stroke: rgba(65, 65, 65, 1) !important;
            }
        }
        cursor: default !important;
        user-select: none !important;
        &:active {
            pointer-events: none !important;
        }
    }
}
