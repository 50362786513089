.claim-reward-modal {
    min-width: 520px;
    .infor-claim {
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        row-gap: 16px;
        .row-infor {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .title {
                color: var(--black, #111);
                /* Title XXS */
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }
            .value {
                color: var(--black, #111);
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                &.reward {
                    font-weight: 500;
                    text-transform: uppercase;
                }
            }
        }
    }
    .button-frame {
        margin-top: 24px;
        display: flex;
        column-gap: 24px;
        * {
            flex: 1;
        }
    }

    @media screen and (max-width: 668px) {
        min-width: 0;
        width: 100%;
        .infor-claim {
            .row-infor {
                
                .title {
                    font-size: 16px;
                }
                .value {
                    font-size: 16px;
                }
            }
        }
        .button-frame {
            margin-top: 40px;
            column-gap: 24px;
            
        }
    }

    @media screen and (max-width: 386px){
        .infor-claim {
            row-gap: 12px;
            margin-top: 24px;
            .row-infor {
                flex-direction: column;
                align-items: flex-start;
                
            }
        }
        .button-frame {
            margin-top: 24px;
            column-gap: 24px;
            flex-direction: column;
            row-gap: 16px;
            * {
                width: 100%;
            }
        }
    }
}