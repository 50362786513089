.table-campaign {
    width: 100%;
    container-name: createdCampaign;
    container-type: inline-size;

    table {
        width: 100%;

        thead {
            th {
                color: var(--neutral-3, #b8b8b8);

                /* Button S */
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;

                text-align: left;
                padding: 24px 0;
                border-bottom: 1px solid var(--neutral-2, #414141);

                .th-infor {
                    display: flex;
                    align-items: center;
                    column-gap: 4px;
                    position: relative;
                    width: max-content;

                    [role="icon-default"]:hover {
                        &~[role="tooltip"] {
                            transform: scale(1);
                        }

                        cursor: pointer;
                        opacity: 0.8;
                    }

                    [role="tooltip"] {
                        top: calc(100% + 6px);
                        right: 0;
                        transform-origin: right top;

                        &::before {
                            top: -6px;
                            height: 6px;
                        }
                    }
                }
            }
        }
    }

    .list-mobile {
        display: none;
        width: 100%;
        flex-direction: column;
        flex-wrap: wrap;
        column-gap: 16px;
        justify-content: space-between;
    }

    @mixin mobile () {
        .table {
            display: none;
        }

        .list-mobile {
            display: flex;
        }
    }

    @container createdCampaign (max-width: 840px) {
        @include mobile();
    }

    @media screen and (max-width: 560px) {
        @include mobile();
    }
}