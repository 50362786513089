.add-reward-modal {
  min-width: 520px;
  width: 100%;

  .info-balance {
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    column-gap: 16px;
    &__left {
      svg {
        width: 64px;
        height: 64px;
      }
    }
    &__right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      * {
        font-size: 16px;
      }
      .address-campaign {
        word-break: break-all;
        margin-bottom: 12px;
      }
    }
  }
  .infor-claim {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    .row-infor {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  .button-frame {
    margin-top: 24px;
    display: flex;
    column-gap: 24px;
    width: 100%;
    .btn {
      // flex: 1;
      width: calc(50% - 12px);
    }
  }
  .title {
    color: var(--black, #111);
    /* Title XXS */
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  span {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .form {
    display: flex;
    flex-direction: row;
    gap: 16px;
    width: 100%;
    margin-top: 16px;
    .button {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid rgba(17, 17, 17, 1);
      width: 61px;
      height: 40px;
      padding: 8px 16px 8px 16px;
      border-radius: 100px;
      gap: 6px;
    }
  }

  @media screen and (max-width: 840px) {
    min-width: 0;
    .info-balance {
      &__left {
        svg {
          width: 48px;
          height: 48px;
        }
      }
      &__right {
        * {
          font-size: 14px;
        }
      }
    }
    .title {
      font-size: 14px;
    }
    .button-frame {
      column-gap: 16px;
      .btn {
        // flex: 1;
        width: calc(50% - 8px);
      }
    }
  }
}
