.back {
    display: flex;
    align-items: center;
    column-gap: 16px;
    label {
        color: var(--white, #FFF);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
}