.popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9999;
    visibility: hidden;
    background: var(--black, #111);
    display: flex;
    justify-content: center;
    max-height: 100vh;
    overflow: auto;
    &.open {
        visibility: visible;
    }
    .container {
        height: max-content;
        min-height: 100vh;
        padding-bottom: 40px;
        padding: 40px 32px;
        max-width: 1460px;
        width: 100%;
        box-sizing: border-box;
        .close {
            display: flex;
            width: 100%;
            justify-content: flex-end;
            &-btn {
            }
        }
        .child-mascot {
            margin-top: 24px;
            display: flex;
            flex-direction: column;
            row-gap: 48px;
            justify-content: center;
            align-items: center;
            .view-on {
                color: var(--white, #fff);
                text-align: center;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                a {
                    color: var(--green, #9affd4);
                }
            }
        }
    }
}
