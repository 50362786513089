.dropdown {
    position: relative;
    &-btn {
        padding: 8px 16px;
        font-size: 14px;
        font-weight: 500;
        [role="icon-default"] {
            width: 12px;
            height: 12px;
        }
    }
    .dropdown-list {
        display: flex;
        padding: 8px 0;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        border-radius: 16px;
        background: #292929;
        position: absolute;
        right: 0;
        width: 100%;
        min-width: max-content;
        top: calc(100% + 2px);
        overflow: hidden;
        transform: scale(0);
        transition: 0.2s;
        transform-origin:  top;
        @media screen and (max-width: 1200px) {
            left: 0;
            right: initial;
        }
        &.open {
            transform: scale(1);
        }
        .item {
            color: #FFF;
            /* Body XS */
            font-size: 14px;
            padding: 8px 16px;
            cursor: pointer;
            width: 100%;
            &:hover {
                background-color: #1E1E1E;
            }
        }
    }
}