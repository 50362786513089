.infor-campaign {
  display: flex;
  align-items: center;
  column-gap: 16px;

  .logo {
    border-radius: 100rem;
    width: 48px;
    height: 48px;
  }

  .information {
    display: flex;
    flex-direction: column;
    row-gap: 6px;

    .name-campaign {
      color: #fff;
      /* Button S */
      font-size: 14px;
      font-weight: 500;
    }

    .name-collection {
      color: #b8b8b8;
      /* Body XXS */
      font-size: 12px;
      font-family: Poppins;
    }
  }
}

.frame-btn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 16px;
}

.row-mobile {
  display: none;
  column-gap: 16px;
  width: 100%;
  border-bottom: 1px solid #b8b8b8;
  padding: 24px 0;
  &:last-child {
    border-bottom: none;
  }
  .logo {
    width: 48px;
    height: 48px;
    border-radius: 100rem;
  }
  .infor {
    display: flex;
    flex-direction: column;
    flex: 1;
    .campaign-name {
      color: var(--white, #fff);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    .collection-name {
      color: var(--neutral-3, #b8b8b8);

      /* Body XXS */
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-top: 6px;
      margin-bottom: 6px;
    }
    .row-infor {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 6px;
      &__title {
        color: var(--neutral-3, #b8b8b8);
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      &__value {
        color: var(--white, #fff);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
    .footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 12px;
      width: 100%;
      .interaction {
        display: flex;
        align-items: center;
        column-gap: 16px;
        justify-content: flex-end;
        width: 100%;
      }
    }
  }
}
