.tabdash {
    display: flex;
    column-gap: 24px;
    align-items: center;
    // border-bottom: 1px solid #B8B8B8;
    padding-bottom: 16px;
    position: relative;
    overflow-x: auto;
    overflow-y: initial;
    &::before {
        content: '';
        position: absolute;
        bottom: 1px;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: #B8B8B8;
    }
    &::-webkit-scrollbar {
        display: none;
    }
    @media screen and (max-width: 840px) {
        margin: 0 -20px;
        padding-left: 20px;
        padding-right: 20px;
    }
    .indicator {
        position: absolute;
        bottom: 2px;
        border-bottom: 3px solid #7F6AFF;
        transition: 0.3s;
        transform: translateY(2px);
    }
    .item {
        color: #FFF;
        /* Button M */
        font-size: 16px;
        font-family: Poppins;
        font-weight: 500;
        cursor: pointer;
        user-select: none;
        white-space: nowrap;
    }
}