.list-campaign {
  margin-bottom: 40px;
  width: 100%;
  container-name: card;
  container-type: inline-size;

  .table {
    width: 100%;

    th {
      @mixin typo() {
        color: #b8b8b8;
        /* Button S */
        font-size: 14px;
        font-family: Poppins;
        font-weight: 500;
      }

      white-space: nowrap;
      @include typo();

      * {
        @include typo();
      }

      padding-bottom: 8px;
    }

    th,
    td {
      text-align: left;
      padding: 24px 0;
      border-bottom: 1px solid #414141;

      &:not(:first-child) {
        padding-left: 16px;
      }
    }

    tbody {
      td {
        @mixin typography() {
          color: var(--white, #fff);
          /* Body XS */
          font-family: Poppins;
        }

        @include typography();

        * {
          @include typography();
        }
      }

      tr:last-child {
        td {
          border-bottom: none;
        }
      }

      .infor-campaign {
        display: flex;
        align-items: center;
        column-gap: 16px;
        max-width: 666px;

        .logo {
          border-radius: 100rem;
          width: 48px;
          height: 48px;
        }

        .information {
          display: flex;
          flex-direction: column;
          row-gap: 6px;

          .name-campaign {
            color: #fff;
            /* Button S */
            font-size: 14px;
            font-weight: 500;
          }

          .name-collection {
            color: #b8b8b8;
            /* Body XXS */
            font-size: 12px;
            font-family: Poppins;
          }
        }
      }

      .interaction {
        display: flex;
        column-gap: 16px;
        justify-content: flex-end;
      }
    }
  }

  .list-mobile {
    display: none;
    width: 100%;
    flex-direction: column;
    flex-wrap: wrap;
    column-gap: 16px;
    justify-content: space-between;

    .list-mobile__item {
      display: flex;
      column-gap: 16px;
      width: 100%;
      border-bottom: 1px solid #b8b8b8;
      padding: 24px 0;

      &:last-child {
        border-bottom: none;
      }

      .logo {
        width: 48px;
        height: 48px;
        border-radius: 100rem;
      }

      .infor {
        display: flex;
        flex-direction: column;
        flex: 1;

        .campaign-name {
          color: var(--white, #fff);
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }

        .collection-name {
          color: var(--neutral-3, #b8b8b8);

          /* Body XXS */
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-top: 6px;
          margin-bottom: 6px;
        }

        .row-infor {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 6px;

          &__title {
            color: var(--neutral-3, #b8b8b8);
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }

          &__value {
            color: var(--white, #fff);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }

        .footer {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 12px;

          .interaction {
            display: flex;
            align-items: center;
            column-gap: 16px;
          }
        }
      }
    }
  }

  @mixin mobile () {
    .table {
      display: none;
    }

    .list-mobile {
      display: flex;
    }
  }

  @container card (max-width: 840px) {
    @include mobile();
  }

  @media screen and (max-width: 560px) {
    @include mobile();
  }
}