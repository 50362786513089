.loading {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 100vw;
    max-height: 100vh;

    &.screen {
        width: 100vw;
        height: 100vh;
    }

    .player {
        width: 240px;
        height: 240px;

        * {
            stroke: #7F6AFF !important;
        }

        @media screen and (max-width: 360px) {
            width: 160px;
            height: 160px;
        }
    }
}