.public-campaign {
  margin-bottom: 24px;
}
.filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 48px;
  position: relative;
  z-index: 10;
  margin-bottom: 40px;
}

.information {
  display: flex;
  align-items: stretch;
  column-gap: 16px;
  margin-top: 32px;
  margin-bottom: 48px;
}

.mascot-notice {
  .start-staking {
    margin-top: 32px;
  }
}

.body-staked-campaign {
  margin-top: 32px;
}

@media screen and (max-width: 1200px) {
  .filter {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 24px;
  }
}

@media screen and (max-width: 450px) {
  .information {
    flex-wrap: wrap;
    gap: 16px;
  }
}
