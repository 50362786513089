.list-stake-nfts {
  margin-top: 52px;

  .head {
    display: flex;
    align-items: center;
    column-gap: 16px;
    justify-content: space-between;
    .list-btn {
      display: flex;
      gap: 12px;
    }
  }

  .notify {
    display: flex;
    padding: 12px 16px;
    align-items: center;
    gap: 10px;
    margin-top: 40px;
    align-self: stretch;
    border-radius: 16px;
    background: #333;
    .des {
      color: #ffe030;
      margin: 0;
      /* Button XS */
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }

  .body {
    margin-top: 36px;
  }
}

@mixin mobile() {
  .list-stake-nfts {
    margin-top: 52px;

    .head {
      flex-direction: column;
      align-items: flex-start;
      row-gap: 32px;
    }
  }
}

@container OutletMain (max-width: 840px) {
  @include mobile();
}

@media screen and (max-width: 560px) {
  @include mobile();
}
