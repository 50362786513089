.tabs {
    margin-top: 48px;
    margin-bottom: 32px;
}

.search-title {
    color: var(--white, #FFF);
    /* Title XS */
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 48px;
    word-break: break-all;
}

@media screen and (max-width: 555px) {
    .search-title {
        font-size: 18px;
        margin-bottom: 24px;
    }
}