.stake-nfts-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  visibility: hidden;
  background: var(--black, #111);
  display: flex;
  justify-content: center;
  max-height: 100vh;
  overflow: auto;

  &.open {
    visibility: visible;
  }

  .container {
    height: max-content;
    min-height: 100vh;
    padding-bottom: 40px;
    padding: 40px 32px;
    max-width: 1460px;
    width: 100%;
    box-sizing: border-box;

    .close {
      display: flex;
      width: 100%;
      justify-content: flex-end;

      &-btn {
      }
    }

    .title {
      color: var(--white, #fff);
      font-size: 32px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
      margin-top: 32px;
    }

    .sub-title {
      color: var(--white, #fff);
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: none;
    }

    .body {
      margin-top: 32px;
      display: flex;
      column-gap: 74px;
      flex-wrap: wrap;

      .step-staking {
        flex: 1;
        display: flex;
        flex-direction: column;
        row-gap: 48px;
        max-width: 100%;

        .box-step {
          padding: 24px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 32px;
          border-radius: 24px;
          border: 1px solid var(--neutral-3, #b8b8b8);

          &__head {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            &-right {
              display: flex;
              align-items: center;
              column-gap: 16px;

              .number-selected {
                color: var(--white, #fff);

                /* Body XS */
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }

              .clear-all {
                color: var(--white, #fff);
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                text-decoration-line: underline;
                cursor: pointer;
              }
            }
          }
        }

        .description {
          display: flex;
          align-items: center;
          column-gap: 8px;
          color: var(--white, #fff);

          [role='icon-default'] {
            color: rgba(184, 184, 184, 1);
            width: 18px;
            height: 18px;
          }

          span {
            color: var(--white, #fff);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }

        .buy-nft-notice {
          color: #fff;
          text-align: center;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-top: 24px;

          a {
            color: rgba(154, 255, 212, 1);
          }
        }

        .list-nft {
          display: grid;
          grid-template-columns: repeat(4, minmax(0, 1fr));

          @media screen and (max-width: 1400px) {
            grid-template-columns: repeat(3, minmax(0, 1fr));
          }

          @media screen and (max-width: 1215px) {
            grid-template-columns: repeat(2, minmax(0, 1fr));
          }

          @media screen and (max-width: 975px) {
            grid-template-columns: repeat(1, minmax(0, 1fr));
          }

          @media screen and (max-width: 775px) {
            grid-template-columns: repeat(3, minmax(0, 1fr));
          }

          @media screen and (max-width: 565px) {
            grid-template-columns: repeat(2, minmax(0, 1fr));
          }

          @media screen and (max-width: 395px) {
            grid-template-columns: repeat(1, minmax(0, 1fr));
          }

          column-gap: 16px;
          row-gap: 32px;
          width: 100%;

          .nft {
            display: flex;
            flex-direction: column;
            row-gap: 8px;
            position: relative;
            align-items: center;

            &.disabled {
              &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                z-index: 10;
              }

              opacity: 0.6;
            }

            .image {
              width: 175px;
              height: 175px;
              border-radius: 16px;
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              padding: 8px;
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
            }

            .name {
              color: var(--white, #fff);
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            }
          }

          @media screen and (max-width: 680px) {
            row-gap: 24px;

            .nft {
              display: flex;
              flex-direction: column;
              row-gap: 8px;
              position: relative;

              .image {
                width: 144px;
                height: 144px;
              }
            }
          }
        }
      }

      .reach-limit-pool {
        color: #fff;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
      }

      .information {
        flex: 1;
        max-width: 506px;
        display: flex;
        padding: 24px;
        flex-direction: column;
        align-items: flex-start;
        gap: 0px;
        border-radius: 24px;
        background: var(--neutral-1, #292929);
        height: max-content;

        .body-infor {
          display: flex;
          flex-direction: column;
          gap: 32px;
          width: 100%;
          margin-top: 32px;
          margin-bottom: 16px;

          .infor-row {
            display: flex;
            column-gap: 12px;
            align-items: center;
            width: 100%;

            .title-infor {
              flex: 1;
              color: var(--neutral-3, #b8b8b8);
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            }

            .value-div {
              flex: 1;
              display: flex;
              align-items: center;
              column-gap: 6px;

              .aura-logo {
                height: 20px;
                width: 20px;
                object-fit: cover;
                object-position: center;
                border-radius: 100rem;
              }
            }

            .value-infor {
              flex: 1;
              color: var(--white, #fff);
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }
        }

        .des-approve {
          color: var(--neutral-3, #b8b8b8);
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-top: 40px;
          border-radius: 16px;
        }

        .notify {
          display: flex;
          padding: 12px 16px;
          align-items: center;
          gap: 10px;
          margin-top: 40px;
          align-self: stretch;
          border-radius: 16px;
          background: #333;
          .des {
            color: #ffe030;
            margin: 0;
            /* Button XS */
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
        }

        .btn-div {
          display: flex;
          column-gap: 16px;
          align-items: center;
          margin-top: 16px;
          flex-wrap: wrap;
          row-gap: 16px;
        }
      }
    }

    @media screen and (max-width: 1048px) {
      .body {
        column-gap: 40px;
      }
    }

    @media screen and (max-width: 775px) {
      padding: 40px 20px;

      .body {
        flex-direction: column;
        row-gap: 24px;

        .information {
          max-width: none;
        }
      }
    }

    @media screen and (max-width: 698px) {
      .close {
        &-btn {
          padding: 6px;

          svg {
            width: 24px;
            height: 24px;
          }
        }
      }

      .title {
        font-size: 24px;
      }

      .sub-title {
        font-size: 18px;
      }

      .body {
        .information {
          padding: 16px;

          .body-infor {
            gap: 24px;
          }

          .des-approve,
          .notify {
            margin-top: 32px;
          }

          .btn-div {
            display: flex;
            column-gap: 16px;
            align-items: center;
            margin-top: 16px;
          }
        }
      }
    }

    @media screen and (max-width: 360px) {
      .body {
        .information {
          .body-infor {
            gap: 24px;

            .infor-row {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              row-gap: 6px;
            }
          }

          .des-approve {
            margin-top: 28px;
          }

          .btn-div {
            flex-direction: column;
            align-items: flex-start;
            width: 100%;

            .btn {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
