@font-face {
    font-family: 'Poppins';
    src: url(./Poppins-Thin.ttf);
    font-style: normal;
    font-weight: 100;
    font-display: block;
}

@font-face {
    font-family: 'Poppins';
    src: url(./Poppins-ExtraLight.ttf);
    font-style: normal;
    font-weight: 200;
    font-display: block;
}

@font-face {
    font-family: 'Poppins';
    src: url(./Poppins-Light.ttf);
    font-style: normal;
    font-weight: 300;
    font-display: block;
}

@font-face {
    font-family: 'Poppins';
    src: url(./Poppins-Regular.ttf);
    font-style: normal;
    font-weight: 400;
    font-display: block;
}

@font-face {
    font-family: 'Poppins';
    src: url(./Poppins-Medium.ttf);
    font-style: normal;
    font-weight: 500;
    font-display: block;
}

@font-face {
    font-family: 'Poppins';
    src: url(./Poppins-SemiBold.ttf);
    font-style: normal;
    font-weight: 600;
    font-display: block;
}

@font-face {
    font-family: 'Poppins';
    src: url(./Poppins-Bold.ttf);
    font-style: normal;
    font-weight: 700;
    font-display: block;
}

@font-face {
    font-family: 'Poppins';
    src: url(./Poppins-ExtraBold.ttf);
    font-style: normal;
    font-weight: 800;
    font-display: block;
}

@font-face {
    font-family: 'Poppins';
    src: url(./Poppins-Black.ttf);
    font-style: normal;
    font-weight: 900;
    font-display: block;
}