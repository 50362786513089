.claim-reward-modal {
  min-width: 520px;
  .infor-claim {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    .row-infor {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .title {
        color: var(--black, #111);
        /* Title XXS */
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      .value {
        color: var(--black, #111);
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        &.reward {
          font-weight: 500;
          text-transform: uppercase;
        }
      }
    }
  }
  .logo {
    width: 48px;
    height: 48px;
    border-radius: 100rem;
    object-fit: cover;
  }

  .table {
    display: flex;
    flex-direction: column;
    gap: 0;
    .list-nft {
      border-radius: 24px 24px;
      border: 1px solid #b8b8b8;
      position: relative;
      padding: 16px 16px 0 16px;
      overflow: hidden;
      .list-nft-data {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        max-height: 228px;
        overflow-y: auto;
        padding-right: 8px;
        scrollbar-color: red;
        &::-webkit-scrollbar {
          width: 4px;
        }
        &::-webkit-scrollbar-track {
          background-color: #f4f4f4;
        }
        &::-webkit-scrollbar-thumb {
          box-shadow: inset 0 0 6px #9affd4;
        }
      }
      .table-head {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #b8b8b8;
        color: #b8b8b8;
        padding-bottom: 12px;
      }

      .nft {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 12px;
        .reward {
          color: #111;
          font-size: 14px;
          font-weight: 500;
          line-height: 21px;
        }
      }
    }
    .list-nft__end {
      position: absolute;
      bottom: 0;
      left: 0;
      height: 30px;
      width: 100%;
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 34.1%,
        #ffffff 99.38%
      );
    }
  }

  .background {
    background-color: #f3f3f3;
    padding: 12px 16px 12px 12px;
    border-radius: 16px;
  }
  .label-reward {
    display: flex;
    align-items: center;
    gap: 16px;
    label {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
  }
  .button-frame {
    margin-top: 24px;
    display: flex;
    column-gap: 24px;
    * {
      flex: 1;
    }
  }

  @media screen and (max-width: 668px) {
    min-width: 0;
    width: 100%;
    .infor-claim {
      .row-infor {
        .title {
          font-size: 16px;
        }
        .value {
          font-size: 16px;
        }
      }
    }
    .button-frame {
      margin-top: 40px;
      column-gap: 24px;
    }
  }

  @media screen and (max-width: 386px) {
    .infor-claim {
      row-gap: 12px;
      margin-top: 24px;
      .row-infor {
        flex-direction: column;
        align-items: flex-start;
      }
    }
    .button-frame {
      margin-top: 24px;
      column-gap: 24px;
      flex-direction: column;
      row-gap: 16px;
      * {
        width: 100%;
      }
    }
  }
}
