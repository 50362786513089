.notice-no-connect {
    padding: 100px 0;
    .connect-wallet {
        margin-top: 32px;
    }
}
.campaign-created {
    margin-bottom: 24px;
}

.connected-layout {
    .information {
        display: flex;
        align-items: stretch;
        column-gap: 16px;
        margin-top: 32px;
        margin-bottom: 48px;
    }
    .no-campaign {
        padding: 100px 0;
        .add-campaign {
            margin-top: 32px;
        }
    }
}
