.btn-gradient {
  background-image: linear-gradient(
    258.6deg,
    #7f6aff 0%,
    #9affd4 20%,
    #ffffff 40%,
    #7f6aff 60%,
    #9affd4 80%,
    #ffffff 100%
  );
  background-repeat: no-repeat;
  background-size: 400% 400%;
  background-color: #9affd4;
  /* Shadow */

  box-shadow: 0px 0px 12px 0px #7f6aff;
  border-radius: 100px;
  width: max-content;
  padding: 12.5px 16px;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 19px;
  cursor: pointer;
  user-select: none;
  height: auto;
  background-position: 0% 0%;

  transition: 0.2s;

  border: none;

  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 6px;

  text-decoration: none;

  [role='icon-default'] {
    width: 16px;
    height: 16px;
  }

  // &:hover {
  //     animation: identifier 2s infinite linear;

  //     @keyframes identifier {
  //         0% {
  //             background-position: 0% 0%;
  //         }

  //         100% {
  //             background-position: 100% 100%;
  //         }
  //     }
  // }

  &:hover {
    color: #fff !important;
    background: #7f6aff;
    background-image: none;
  }

  &.disabled,
  &.loading {
    background: var(--neutral-2, #414141);
    color: var(--neutral-3, #b8b8b8) !important;
    text-align: center;
    /* Button S */
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    box-shadow: none;
    cursor: default;

    &:active {
      pointer-events: none;
    }
  }

  .loading-frame {
    // width: 16px;
    height: 16px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    .player {
      width: 64px;
      height: 64px;

      * {
        stroke: #fff !important;
      }
    }
  }
}
