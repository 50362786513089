.tooltip {
  padding: 8px 12px;
  background: #414141;
  border-radius: 8px;
  position: absolute;
  transition: 0.3s;
  transform: scale(0);
  color: var(--white, #fff);
  text-align: center;
  /* Body XXS */
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  white-space: initial;
  z-index: 100;
  width: max-content;
  max-width: 120px;

  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    left: 0;
    right: 0;
  }
}
