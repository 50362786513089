.list-staked-campaign {
    width: 100%;
    margin-bottom: 40px;
    container-name: listStaked;
    container-type: inline-size;

    .table {
        width: 100%;

        th {
            @mixin typo() {
                color: #b8b8b8;
                /* Button S */
                font-size: 14px;
                font-family: Poppins;
                font-weight: 500;
            }

            @include typo();

            * {
                @include typo();
            }

            padding-bottom: 8px;
        }

        th,
        td {
            text-align: left;
            padding: 24px 0;
            border-bottom: 1px solid #414141;
        }

        tbody {
            td {
                @mixin typography() {
                    color: var(--white, #fff);
                    /* Body XS */
                    font-family: Poppins;
                }

                @include typography();

                * {
                    @include typography();
                }
            }

            tr:last-child {
                td {
                    border-bottom: none;
                }
            }
        }
    }

    .list-mobile {
        display: none;
        flex-direction: column;
        width: 100%;
        flex-wrap: wrap;
        column-gap: 16px;
        justify-content: space-between;
    }

    @mixin mobile () {
        .table {
            display: none;
        }

        .list-mobile {
            display: flex;

            .list-mobile__item {
                display: flex;
            }

            tr.list-mobile__item {
                display: none !important;
            }
        }
    }

    @container listStaked (max-width: 840px) {
        @include mobile();
    }

    @media screen and (max-width: 560px) {
        @include mobile();
    }
}