.header {
  display: flex;
  align-items: center;
  column-gap: 24px;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 48px;
  position: relative;
  z-index: 100;
  .left-side {
    display: flex;
    align-items: center;
    column-gap: 16px;
  }
  .logo-icon {
    display: none;
    height: 28px;
  }
  .search-mobile {
    display: none;
  }
  .search {
    background: #242424;
    border-radius: 100rem;
    padding: 8px 16px;
    align-items: center;
    column-gap: 6px;
    display: flex;
    max-width: 371px;
    flex: 1;

    [role='icon-default'] {
      color: #b0b0b0;
      cursor: pointer;
    }

    input {
      background-color: transparent;
      border: none;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      /* identical to box height */

      /* Neutral 2 */

      color: #fff;
      outline: none;
      // flex: 1;
      width: calc(100% - 22px);
    }
  }

  .frame-btn {
    display: flex;
    align-items: center;
    column-gap: 32px;

    .network {
      display: flex;
      align-items: center;
      column-gap: 6px;

      img {
        height: 24px;
      }

      label {
        color: #fff;
        text-align: center;

        /* Button S */
        font-size: 14px;
        font-family: Poppins;
        font-weight: 500;
      }
    }
  }

  .btn-connect {
    position: relative;

    .dropdown {
      position: absolute;
      right: 0;
      top: calc(100% + 16px);
      background-color: #292929;
      padding: 8px 0;
      border-radius: 16px;
      display: flex;
      flex-direction: column;
      width: max-content;
      box-shadow: 0 2px 6px #3b3b3b;
      visibility: hidden;
      &.open {
        visibility: visible;
      }
      .type-wallet {
        display: flex;
        align-items: center;
        column-gap: 16px;
        justify-content: space-between;
        padding: 8px 16px;
        .wallet-name {
          color: var(--white, #fff);
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
        .wallet-label {
          color: var(--neutral-3, #b8b8b8);
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
      &-item {
        color: #fff;
        display: flex;
        align-items: center;
        column-gap: 6px;
        cursor: pointer;
        padding: 8px 16px;

        &:hover {
          background-color: rgba(255, 255, 255, 0.04);
        }

        * {
          cursor: pointer;
        }
      }

      &::before {
        position: absolute;
        top: -16px;
        height: 16px;
        left: 0;
        width: 100%;
      }
    }
  }

  @media screen and (max-width: 1015px) {
    .logo-icon {
      display: block;
    }
  }
  @media screen and (max-width: 768px) {
    .network {
      label {
        display: none;
      }
    }
  }
  @media screen and (max-width: 580px) {
    .btn-login {
      span {
        display: none;
      }
    }
    .frame-btn {
      column-gap: 16px;
    }
  }
  @media screen and (max-width: 475px) {
    .left-side {
      .search {
        display: none;
      }
    }
    .search-mobile {
      display: flex;
    }
    &.in-search-page {
      .left-side {
        width: 100%;
        .search {
          display: flex;
          flex: 1;
          max-width: none;
        }
      }
      .search-mobile {
        display: none;
      }
      .frame-btn {
        display: none;
      }
    }
  }
}
