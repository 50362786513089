.sub-title {
    color: var(--white, #fff);
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: none;
}

@container OutletMain (max-width: 840px) {
    .sub-title {
        font-size: 18px;
    }
}

.title-modal {
    color: var(--black, #111);
    text-align: center;

    /* Title XS */
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: none;

    @media screen and (max-width: 668px) {
        font-size: 18px;
    }
}

.status {
    display: flex;
    align-items: center;
    column-gap: 6px;
    color: #fff;

    .circle {
        width: 8px;
        height: 8px;
        border-radius: 100rem;
        background: gray;
    }

    &.active {
        .circle {
            background: linear-gradient(225deg, #7f6aff 0%, #9affd4 50%, #fff 100%);
        }
    }

    &.upcoming {
        .circle {
            background: #7f6aff;
        }
    }

    &.ended {
        .circle {
            background: #cf6679;
        }
    }
}

.btn {
    .lf-player-container {
        display: block;
    }
}

.lf-player-container {
    max-width: 100%;
    display: flex;
}