.sidebar {
  width: 360px;
  border-right: 1px solid #b0b0b0;
  min-height: 100vh;
  min-width: 360px;
  position: relative;

  .content {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    width: 360px;
    display: flex;
    flex-direction: column;
    overflow: auto;
  }
}

.logo-sidebar {
  text-decoration: none;

  img {
    height: 32px;
    margin: 40px;
  }
}

.bottom {
  padding-left: 40px;
  margin-right: 1px;
  padding-right: 39px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  bottom: 24px;
  position: relative;
  margin-top: auto;
  right: 0;
  left: 0;
  // background-color: #111;

  .how-to {
    display: flex;
    padding: 24px 0;
    column-gap: 24px;
    text-decoration: none;
    border-top: 1px solid #414141;

    &:hover {
      cursor: pointer;

      // background-color: rgba(255, 255, 255, 0.04);

      * {
        cursor: pointer;
      }
    }

    label {
      color: #fff;
      font-weight: 500;
      font-size: 18px;
      line-height: 27px;
    }
  }
}

.navigate {
  margin-top: 50px;
  margin-bottom: 24px;

  .item-sidebar {
    display: flex;
    column-gap: 24px;
    padding: 24px 40px;
    align-items: center;
    transition: 0.3s;
    text-decoration: none;

    * {
      cursor: pointer;
    }

    img {
      filter: grayscale(100%);
    }

    label {
      color: #fff;
      font-weight: 500;
      font-size: 18px;
      line-height: 27px;

      &.label-mobile {
        display: none;
      }
    }

    &:hover,
    &.active {
      cursor: pointer;
    }

    &:hover {
      background-color: rgba(255, 255, 255, 0.04);
    }

    &.active {
      background-color: rgba(255, 255, 255, 0.08);

      img {
        filter: grayscale(0%);
      }
    }
  }

  .howto {
    display: none;
  }
}

@media screen and (max-width: 1015px) {
  .sidebar {
    width: 100%;
    border-right: none;
    min-height: 0;
    min-width: 0;
    position: fixed;
    left: 0;
    bottom: 0;
    border-radius: 24px 24px 0px 0px;
    background: var(--neutral-1, #292929);
    z-index: 10;
    overflow: hidden;

    .content {
      position: relative;
      width: 100%;
      padding: 6px 16px;

      .logo-sidebar {
        display: none;
      }

      .navigate {
        margin-top: 0;
        flex-direction: row;
        display: flex;
        justify-content: space-between;
        column-gap: 16px;
        margin-bottom: 0;

        .item-sidebar {
          display: flex;
          column-gap: 24px;
          padding: 6px 20px;
          text-decoration: none;
          flex-direction: column;
          row-gap: 6px;
          flex: 1;

          label {
            font-size: 10px;
            line-height: normal;
            text-align: center;

            &.label-mobile {
              display: block;
            }

            &.label-pc {
              display: none;
            }
          }

          &:hover,
          &.active {
            cursor: pointer;
          }

          &:hover {
            background-color: transparent;
          }

          &.active {
            background-color: transparent;
          }
        }

        .howto {
          display: flex;
        }
      }

      .bottom {
        display: none;
      }
    }
  }
}