.body {
    margin-top: 24px;
    display: flex;
    column-gap: 40px;
    justify-content: space-between;
    align-items: center;
    max-width: 840px;
    .christmas-mobile {
        display: none;
        width: 182px;
    }
    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        background-color: #fff;
    }
    &::-webkit-scrollbar {
        width: 6px;
        height: 0px;
        background-color: #f5f5f5;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background: linear-gradient(258.6deg, #7f6aff 0%, #9affd4 100%);
    }
    .content {
        display: flex;
        flex-direction: column;
        height: 100%;
        .title {
            color: var(--black, #111);
            /* Title S */
            font-size: 32px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%; /* 32px */
        }
        .description {
            color: var(--black, #111);
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-top: 16px;
        }
        .des-2 {
            margin-top: 80px;
            color: var(--black, #111);

            /* Body S */
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-bottom: 16px;
        }
    }
    .christmas {
        width: 320px;
    }
    @media screen and (max-width: 820px) {
        .christmas-mobile {
            display: initial;
            margin-top: 40px;
            margin-bottom: 40px;
        }
        margin-top: 16px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            * {
                text-align: center;
            }
            .title {
                font-size: 24px;
            }
            .description {
                font-size: 16px;
            }
            .des-2 {
                margin-top: 0px;

                /* Body S */
                font-size: 14px;
            }
        }
        .christmas {
            display: none;
        }
    }
}
