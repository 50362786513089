.mascot-notice {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding: 48px 0;

    .notice {
        color: var(--white, #fff);
        text-align: center;

        /* Title S */
        font-size: 32px;
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;

        @media screen and (max-width: 460px) {
            font-size: 24px;
            line-height: 120%;
        }
    }

    .mascot {
        margin-top: 64px;
        width: 460px;
    }

    .aura {
        background: var(--gradient,
                linear-gradient(258.6deg,
                    rgba(127, 106, 255, 1) 0%,
                    rgba(154, 255, 212, 1) 50%,
                    rgba(255, 255, 255, 1) 100%));
        border-radius: 50%;
        flex-shrink: 0;
        width: 240px;
        height: 240px;
        position: absolute;
        left: calc(50% - 120px);
        top: calc(50% - 120.25px);
        filter: blur(120px);
        animation: aura 4s infinite linear;

        @keyframes aura {
            0% {
                transform: scale(0.6);
            }

            50% {
                transform: scale(1);
                opacity: 1;
            }

            100% {
                transform: scale(0.6);
            }
        }
    }

    .btn {
        margin-top: 96px;
    }
}

@container OutletMain (max-width: 840px) {
    .mascot-notice {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        padding: 24px 0;

        .notice {
            font-size: 18px;
        }

        .mascot {
            margin-top: 36px;
            width: 240px;
        }

        .aura {
            width: 180px;
            height: 180px;
            left: calc(50% - 90px);
            top: calc(50% - 96px);
            filter: blur(90px);
        }

        .btn {
            margin-top: 48px;
        }
    }
}