.information-campaign-container {
  container-name: InformationCampaign;
  container-type: inline-size;
  .information-campaign {
    display: flex;
    align-items: stretch;
    column-gap: 16px;
    margin-top: 48px;
    .collection-name {
      color: var(--Purple, #7f6aff) !important;
    }
  }
  .card {
    padding: 24px;
    flex: 1 0 0;

    border-radius: 24px;
    background: var(--neutral-1, #292929);
    &.infor-base {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;
      .head {
        display: flex;
        align-items: center;
        gap: 24px;
        .logo {
          width: 120px;
          height: 120px;
          border-radius: 100rem;
        }
        .infor {
          display: flex;
          flex-direction: column;
          row-gap: 8px;
          .name-campaign {
            display: flex;
            gap: 8px;
            align-items: center;
            label {
              color: var(--white, #fff);
              /* Button M */
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            }
            .aura-icon {
              position: relative;
              img {
                height: 24px;
              }
              &:hover {
                [role='tooltip'] {
                  transform: scale(1);
                }
              }
              [role='tooltip'] {
                top: calc(100% + 6px);
                right: 0;
                transform-origin: right top;
                max-width: 86px;
                &::before {
                  top: -6px;
                  height: 6px;
                }
              }
            }
          }
          .owner {
            color: #fff;

            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            a {
              color: rgba(154, 255, 212, 1);
              text-decoration: none;
            }
          }
        }
      }
      .description {
        color: var(--white, #fff);
        /* Body XS */
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
    &.infor-table {
      display: flex;
      column-gap: 16px;
      flex-wrap: wrap;
      row-gap: 24px;
      .column {
        display: flex;
        flex-direction: column;
        flex: 1;
        row-gap: 24px;
        .cell {
          display: flex;
          flex-direction: column;
          row-gap: 8px;
          .title {
            color: var(--neutral-3, #b8b8b8);
            /* Button S */
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            word-break: break-all;
            display: flex;
            align-items: center;
            column-gap: 4px;
            position: relative;
            width: max-content;
            [role='icon-default'] {
              color: #b8b8b8;
              width: 12px;
              height: 12px;
              cursor: pointer;

              &:hover {
                opacity: 0.8;
                & ~ .tooltip-cell {
                  transform: scale(1);
                }
              }
            }
            .tooltip-cell {
              bottom: calc(100% + 6px);
              right: -22px;
              // transform-origin: top right;
              max-width: 220px;
              &::after {
                content: '';
                position: absolute;
                top: 100%;
                right: 20px;
                margin-left: -5px;
                border-width: 9px;
                border-style: solid;
                border-color: #414141 transparent transparent transparent;
              }
            }
          }
          .value {
            color: var(--white, #fff);
            /* Body XS */
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }

          .staking-periods {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 4px;
            .list-term {
              display: flex;
              gap: 16px;
            }
          }

          &.reward-token {
            .value {
              display: flex;
              align-items: center;
              column-gap: 8px;
              .aura-logo {
                height: 16px;
                width: 16px;
                border-radius: 100rem;
                object-fit: cover;
                object-position: center;
              }
            }
          }
        }
      }
    }
  }

  @container InformationCampaign (max-width: 840px) {
    .information-campaign {
      flex-direction: column;
      row-gap: 16px;
      margin-top: 40px;
      .card {
        padding: 16px;
        &.infor-base {
          .head {
            gap: 16px;
            .logo {
              width: 48px;
              height: 48px;
            }
          }
        }
      }
    }
  }
}
