.modal {
    background-color: #fff;
    padding: 40px 40px 64px 40px;
    flex-direction: column;
    align-items: flex-end;
    gap: 24px;
    flex-shrink: 0;
    border-radius: 40px;
    position: relative;
    z-index: 1;
    margin: auto;
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    max-width: 100%;
    max-height: 96vh;
    overflow-y: auto;
    .close {
        padding: 8px;
        border: 1px solid rgba(17, 17, 17, 1);
        color: rgba(17, 17, 17, 1);
        border-radius: 100rem;
        display: flex;
        align-items: center;
        justify-content: center;
        @mixin common() {
            cursor: pointer;
            transition: 0.2s;
        }
        @include common();
        * {
            @include common();
        }
        &:hover {
            background-color: rgba(17, 17, 17, 1);
            color: #fff;
        }
        [role="icon-default"] {
            width: 24px;
            height: 24px;
        }
    }
    .body {
        width: 100%;
        height: max-content;
    }
    @media screen and (max-width: 668px) {
        padding: 24px;
        row-gap: 16px;
        min-width: 100%;
        .close {
            [role="icon-default"] {
                width: 16px;
                height: 16px;
            }
        }
    }
}
