.lockup-terms {
    display: flex;
    align-items: stretch;
    column-gap: 16px;
    row-gap: 12px;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
    .term {
        display: flex;
        padding: 16px;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 6px;
        flex: 1 0 0;
        border-radius: 16px;
        background: var(--neutral-1, #292929);
        transition: 0.2s;
        cursor: pointer;
        user-select: none;
        min-width: 130px;
        * {
            cursor: pointer;
            transition: 0.2s;
        }
        &.active {
            background: var(--green, #9affd4);
            .title,
            .value {
                color: var(--black, #111);
            }
        }
        .title {
            color: var(--white, #fff);
            text-align: center;

            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
        .value {
            color: var(--white, #fff);

            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }
}
