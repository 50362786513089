.title {
    margin-top: 48px;
    margin-bottom: 32px;
}

.box-infor-frame {
    display: flex;
    column-gap: 24px;
    align-items: stretch;
    width: 100%;
    margin-bottom: 48px;
}

.chart {
    margin-top: 32px;
    margin-bottom: 32px;
}

.lockup-term-note {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 16px;
    flex-wrap: wrap;
    row-gap: 24px;
}

@container OutletMain (max-width: 840px) {
    .box-infor-frame {
        flex-direction: column;
        row-gap: 24px;
    }
    .lockup-term-note {
    }
}
