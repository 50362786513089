.popup-base {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    visibility: hidden;
    opacity: 0;
    transition: 0.3s;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    padding: 20px;
    .backdrop {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(17, 17, 17, 0.8);
        z-index: 0;
    }
    &.open {
        visibility: visible;
        opacity: 1;
    }
}