@import '../../../assets/styles/index.scss';

.btn-primary {
  border-radius: 24px;
  background-color: #111111;
  font-weight: 500;
  color: #fff;
  font-size: 1.4rem;
  line-height: 24px;
  padding: 9.5px 16px;
  border: none;
  cursor: pointer;
  text-decoration: none;
  width: max-content;
  position: relative;
  font-family: inherit;
  display: flex;
  // flex-wrap: wrap;
  text-align: center;
  align-items: center;
  justify-content: center;
  column-gap: 6px;
  height: 44px;

  [role='icon-default'] {
    width: 16px;
    height: 16px;
  }

  @media screen and (max-width: $mobile-width) {
    font-size: 1.4rem;
  }

  &.center {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }

  &.disabled {
    cursor: default !important;
    opacity: 0.6 !important;
    user-select: none !important;
    background-color: var(--neutral-2, #414141);

    &:active {
      pointer-events: none !important;
    }
  }

  &:hover {
    border-style: solid;
    border-width: 1px;
    transition: 0.2s !important;
    background-color: transparent !important;
    color: #111111;
  }

  &.outline {
    border-style: solid;
    border-width: 1px;
    background-color: transparent !important;
    transition: 0.2s !important;
    color: #fff;
    border-color: currentColor;

    &.disabled {
      border: 1px solid var(--neutral-2, #414141) !important;
      color: var(--neutral-2, #414141) !important;

      [role='icon-default'] {
        color: var(--neutral-2, #414141) !important;
      }
    }

    &:not(.disabled):hover {
      background-color: #292929 !important;
      color: #fff !important;
      border-color: #fff;

      [role='icon-default'] {
        color: #fff;
      }
    }
  }

  &.loading {
    background: var(--neutral-2, #414141);
    color: var(--neutral-3, #b8b8b8) !important;
    text-align: center;
    /* Button S */
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    box-shadow: none;
    cursor: default;

    &:active {
      pointer-events: none;
    }
  }

  .loading-frame {
    // width: 16px;
    height: 16px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    .player {
      width: 64px;
      height: 64px;

      * {
        stroke: #fff !important;
      }
    }
  }
}

.link-href {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
