.copy-right {
  display: flex;
  padding: 40px 0 16px;
  gap: 24px;
  text-decoration: none;
  border-top: 1px solid #414141;
  flex-direction: column;
  label {
    color: rgba(184, 184, 184, 1);
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
  }
  .follow {
    display: flex;
    gap: 24px;
  }
  &.mobile {
    display: none;
    @media screen and (max-width: 1015px) {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
