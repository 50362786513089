.table-staking-history {
  width: 100%;

  .table {
    width: 100%;
    width: 100%;
    margin-bottom: 16px;

    th,
    td {
      text-align: left;
      border-bottom: 1px solid var(--neutral-2, #414141);
    }

    thead {
      th {
        color: var(--neutral-3, #b8b8b8);
        /* Button S */
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        padding-bottom: 8px;
      }
    }

    tbody {
      tr {
        &:last-child {
          td {
            border-bottom: none;
          }
        }
      }

      td {
        padding: 24px 0;
        color: var(--white, #fff);

        /* Body XS */
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        a {
          text-decoration: none;
          color: currentColor;
        }

        .time-date {
          display: flex;
          flex-direction: column;
        }

        .nft {
          display: flex;
          align-items: center;
          column-gap: 16px;

          .logo {
            width: 48px;
            height: 48px;
            border-radius: 100rem;
          }

          .name {
            font-weight: 500;
          }
        }
      }
    }
  }

  .list-mobile {
    display: none;
    width: 100%;
    flex-direction: column;
    flex-wrap: wrap;
    column-gap: 16px;
    justify-content: space-between;

    .list-mobile__item {
      display: flex;
      column-gap: 16px;
      width: 100%;
      border-bottom: 1px solid #b8b8b8;
      padding: 24px 0;

      .logo {
        width: 48px;
        height: 48px;
        border-radius: 100rem;
      }

      .infor {
        display: flex;
        flex-direction: column;
        flex: 1;

        .campaign-name {
          color: var(--white, #fff);
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }

        .collection-name {
          color: var(--neutral-3, #b8b8b8);

          /* Body XXS */
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-top: 6px;
          margin-bottom: 6px;
        }

        .row-infor {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 6px;

          &__title {
            color: var(--neutral-3, #b8b8b8);
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }

          &__value {
            color: var(--white, #fff);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-decoration: none;
          }
        }

        .footer {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 12px;

          &-label {
            color: var(--white, #fff);
            /* Body XS */
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
      }
    }
  }

  @mixin mobile () {
    .table {
      display: none;
    }

    .list-mobile {
      display: flex;
    }
  }

  @container OutletMain (max-width: 840px) {
    @include mobile();
  }

  @media screen and (max-width: 560px) {
    @include mobile();
  }
}