.infor-card {
  display: flex;
  flex: 1;
  column-gap: 24px;
  border: 1px solid #b0b0b0;
  border-radius: 24px;
  padding: 24px;

  [role='icon-default'] {
    width: 60px;
    height: 60px;
    color: #fff;
  }

  .information {
    display: flex;
    flex-direction: column;
    row-gap: 6px;

    .text {
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 36px;
      /* identical to box height */

      /* Green */

      color: #9affd4;
    }

    .des {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      /* identical to box height */

      /* White */

      color: #ffffff;
    }
  }

  @media screen and (max-width: 1100px) {
    flex-wrap: wrap;
    justify-content: center;
    .information {
      align-items: center;
      .des {
        text-align: center;
      }
    }
  }

  @media screen and (max-width: 1015px) {
    flex-wrap: nowrap;
    justify-content: baseline;
    .information {
      align-items: baseline;
      .des {
        text-align: unset;
      }
    }
  }

  @media screen and (max-width: 768px) {
    padding: 16px;
    flex-direction: column;
    row-gap: 16px;
    min-width: 120px;
    [role='icon-default'] {
      width: 24px;
      height: 24px;
    }

    .information {
      row-gap: 6px;
      .text {
        font-size: 18px;
        line-height: normal;
      }

      .des {
        font-size: 14px;
        line-height: normal;
      }
    }
  }
}
