.form {
  // flex-grow: 1;
  width: calc(100% - 80px);
  display: flex;
  // flex-wrap: wrap;
  align-items: center;
  padding: 10px 16px;
  gap: 16px;
  // width: 100%;
  border: 1px solid rgba(184, 184, 184, 1);
  border-radius: 60px;
  // overflow: hidden;
  input {
    border: none;
    box-shadow: none;
    outline: none;
    flex: 1;
    width: 100%;
    box-sizing: border-box;
    font-size: 16px;
    @media screen and (max-width: 840px) {
      font-size: 14px;
    }
    &:hover {
      background-color: initial;
    }
    &:focus {
      outline: none;
    }
  }
}

// /* Auto layout */
// display: flex;
// flex-direction: row;
// align-items: center;
// padding: 10px 16px;
// gap: 10px;

// margin: 0 auto;
// width: 463px;
// height: 40px;

// /* Neutral 3 */
// border: 1px solid #b8b8b8;
// border-radius: 60px;

// /* Inside auto layout */
// flex: none;
// order: 0;
// align-self: stretch;
// flex-grow: 0;

// /* Enter amount */

// width: 382px;
// height: 21px;

// /* Body XS */
// font-family: 'Poppins';
// font-style: normal;
// font-weight: 400;
// font-size: 14px;
// line-height: 21px;
// /* identical to box height */

// /* Neutral 2 */
// color: #414141;

// /* Inside auto layout */
// flex: none;
// order: 0;
// flex-grow: 1;

// /* ETH */

// width: 39px;
// height: 21px;

// /* Button S */
// font-family: 'Poppins';
// font-style: normal;
// font-weight: 500;
// font-size: 14px;
// line-height: 21px;
// /* identical to box height */

// /* Neutral 2 */
// color: #414141;

// /* Inside auto layout */
// flex: none;
// order: 1;
// flex-grow: 0;

// /* Buttons */

// box-sizing: border-box;

// /* Auto layout */
// display: flex;
// flex-direction: row;
// justify-content: center;
// align-items: center;
// padding: 8px 16px;
// gap: 6px;

// width: 61px;
// height: 40px;

// /* Black */
// border: 1px solid #111111;
// border-radius: 100px;

// /* Inside auto layout */
// flex: none;
// order: 1;
// flex-grow: 0;

// /* wallet-01 */

// display: none;
// width: 16px;
// height: 16px;

// /* Inside auto layout */
// flex: none;
// order: 0;
// flex-grow: 0;

// /* Button */

// width: 29px;
// height: 21px;

// /* Button S */
// font-family: 'Poppins';
// font-style: normal;
// font-weight: 500;
// font-size: 14px;
// line-height: 21px;
// /* identical to box height */
// text-align: center;

// /* Black */
// color: #111111;

// /* Inside auto layout */
// flex: none;
// order: 1;
// flex-grow: 0;

// /* chevron-down */

// display: none;
// width: 16px;
// height: 16px;

// /* Inside auto layout */
// flex: none;
// order: 2;
// flex-grow: 0;
