/* default */

html {
  font-size: 10px;
  font-weight: 400;
}

body {
  margin: 0;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-size: 1.4rem;
  line-height: 1.8rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.stakify {
  // @include theme-aware('background', 'color-background');

  // h1,
  // h2,
  // h3,
  // h4,
  // h5,
  // p,
  // span {
  //   @include theme-aware('color', 'color-text');
  // }

  background-color: #111111;
}

.layout-main {
  display: flex;
  column-gap: 40px;
  max-width: 100%;
}

.outlet-main {
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding-right: 40px;
  overflow-x: hidden;
  container-name: OutletMain;
  container-type: inline-size;
  .container-outlet {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    max-width: 1360px;
    height: 100%;
    width: 100%;
  }
}

@media screen and (max-width: 1015px) {
  .layout-main {
    flex-direction: column-reverse;
  }
  .outlet-main {
    padding-right: 0;
    overflow-x: hidden;
    padding: 0 20px;
    margin-bottom: 60px;
    justify-content: flex-start;
    .container-outlet {
      display: flex;
      flex-direction: column;
      padding-top: 20px;
      max-width: 1360px;
      height: 100%;
      width: 100%;
    }
  }
}

@import './class';
