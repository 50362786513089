.connect-wallet {
    display: flex;
    flex-direction: column;
    max-width: 540px;
    .title {
        color: #111;
        text-align: center;

        /* Title XS */
        font-size: 24px;
        font-weight: 500;
        text-transform: none;
        text-align: center;
        line-height: 36px;
    }
    .des {
        color: #111;
        text-align: center;
        /* Body S */
        font-size: 16px;
        margin-top: 16px;
        line-height: 24px;
    }
    .list-wallet {
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-top: 40px;
        .wallet {
            display: flex;
            flex-direction: column;
            row-gap: 8px;
            justify-content: center;
            align-items: center;
            width: max-content;
            cursor: pointer;
            opacity: 1;
            transition: 0.3s;
            * {
                cursor: pointer;
            }
            &:hover {
                opacity: 0.9;
            }
            .logo {
                width: 80px;
                height: 80px;
                border-radius: 100rem;
            }
            .name {
                color: #111;
                text-align: center;
                /* Body S */
                font-size: 16px;
            }
        }
    }
    @media screen and (max-width: 668px) {
        .title {
            font-size: 18px;
        }
        .des {
            font-size: 14px;
        }
        .list-wallet {
            margin-top: 24px;
            .wallet {
                .logo {
                    width: 64px;
                    height: 64px;
                }
                .name {
                    font-size: 14px;
                }
            }
        }
    }
}