.box-infor {
    padding: 24px;
    border-radius: 24px;
    border: 1px solid var(--neutral-2, #414141);
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    flex: 1;

    .title {
        color: var(--white, #fff);
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .list-box {
        display: flex;
        flex-direction: column;
        row-gap: 16px;

        .row {
            display: flex;
            align-items: center;
            justify-content: space-between;
            column-gap: 16px;

            &-title,
            &-value {
                width: calc(50% - 16px);
            }

            &-title {
                color: var(--neutral-3, #b8b8b8);

                /* Button S */
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }

            &-value {
                color: var(--white, #fff);
                text-align: right;

                /* Body XS */
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }
    }
}

@container OutletMain (max-width: 840px) {
    .box-infor {
        padding: 16px;
    }
}
