.row-created-campaign {
    td {
        @mixin typography() {
            color: var(--white, #fff);
            /* Body XS */
            font-family: Poppins;
        }
        border-bottom: 1px solid var(--neutral-2, #414141);
        padding: 24px 0;
        @include typography();
        * {
            @include typography();
        }
    }
    .infor-campaign {
        display: flex;
        align-items: center;
        column-gap: 16px;
        .logo {
            border-radius: 100rem;
            width: 48px;
            height: 48px;
        }
        .information {
            display: flex;
            flex-direction: column;
            row-gap: 6px;
            .name-campaign {
                color: #fff;
                /* Button S */
                font-size: 14px;
                font-weight: 500;
            }
            .name-collection {
                color: #b8b8b8;
                /* Body XXS */
                font-size: 12px;
                font-family: Poppins;
            }
        }
    }

    .interaction {
        display: flex;
        column-gap: 16px;
        justify-content: flex-end;
    }
    .remaining-rewards {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        row-gap: 6px;
        .label-remaining {
            text-align: center;
            width: 100%;
        }
        .progress {
            min-width: 160px;
            width: 90%;
            height: 4px;
            border-radius: 100rem;
            background-color: #292929;
            .active-progress {
                height: 100%;
                background-color: #7f6aff;
                border-radius: 100rem;
            }
        }
    }
}

.list-mobile__item {
    display: flex;
    column-gap: 16px;
    width: 100%;
    border-bottom: 1px solid #b8b8b8;
    padding: 24px 0;
    .logo {
        width: 48px;
        height: 48px;
        border-radius: 100rem;
    }
    .infor {
        display: flex;
        flex-direction: column;
        flex: 1;
        .campaign-name {
            color: var(--white, #fff);
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
        .collection-name {
            color: var(--neutral-3, #b8b8b8);

            /* Body XXS */
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-top: 6px;
            margin-bottom: 6px;
        }
        .row-infor {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 6px;
            &__title {
                color: var(--neutral-3, #b8b8b8);
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }
            &__value {
                color: var(--white, #fff);
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }
        .footer {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 12px;
            .interaction {
                display: flex;
                align-items: center;
                column-gap: 16px;
            }
        }
    }
}
