.tabs {
    display: flex;
    column-gap: 16px;
    row-gap: 16px;
    flex-wrap: wrap;
    .item {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        /* identical to box height */

        text-align: center;

        /* White */

        color: #FFFFFF;
        background: #292929;
        padding: 8px 16px;
        border-radius: 100rem;
        transition: 0.2s;
        user-select: none;

        * {
            transition: 0.2s;
        }

        &:not(.active) {
            cursor: pointer;

            &:hover {
                opacity: 0.8;
            }

            * {
                cursor: pointer;
            }
        }

        &.active {
            background: #FFFFFF;
            color: #111;
        }
    }
}