.back {
    display: flex;
    align-items: center;
    column-gap: 16px;
    label {
        color: var(--white, #fff);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
}

.notice-no-connect {
    padding: 100px 0;
    .connect-wallet {
        margin-top: 32px;
    }
}
