.your-rewards-container {
    container-name: YourRewards;
    container-type: inline-size;

    .your-rewards {
        border-radius: 24px;
        background: var(--neutral-1, #292929);
        display: flex;
        margin-top: 16px;
        padding: 24px;
        column-gap: 36px;
        width: 100%;
        align-items: flex-start;

        .frame-reward {
            display: flex;
            align-items: center;
            column-gap: 16px;

            [role="icon-default"] {
                width: 24px;
                height: 24px;
                color: #fff;
            }

            label {
                color: var(--white, #fff);
                /* Button M */
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }
        }

        &__child {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            flex: 1;
            column-gap: 16px;
            row-gap: 24px;

            .data-reward {
                display: flex;
                flex-direction: column;
                row-gap: 8px;

                .title {
                    color: var(--neutral-3, #b8b8b8);
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }

                .value {
                    color: var(--white, #fff);
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }

            .approve-frame {
                width: 100%;

                .approve-note {
                    color: var(--neutral-3, #b8b8b8);

                    /* Body XXS */
                    font-family: Poppins;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }

                .approve-btns {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    column-gap: 16px;
                    margin-top: 16px;
                    row-gap: 16px;
                }
            }
        }
    }

    @mixin mobile () {
        .your-rewards {
            justify-content: space-between;
            padding: 16px;
            column-gap: 16px;
            flex-wrap: wrap;
            row-gap: 24px;

            .frame-reward {
                width: 100%;
            }
        }
    }

    @container YourRewards (max-width: 840px) {
        @include mobile();
    }

    @media screen and (max-width: 560px) {
        @include mobile();
    }
}

.toast {
    color: #757575;
    text-decoration: none;

    a {
        color: #757575;
    }
}